* {
  -webkit-overflow-scrolling: touch;
}

.sapUiBlockLayoutSizeXL {
  .sapUiBlockCellContent {
    padding: 0 !important;
  }
}

.sapUiBlockLayoutSizeL {
  .sapUiBlockCellContent {
    padding: 0 !important;
  }
}

.sapUiBlockLayoutSizeM {
  .sapUiBlockCellContent {
    padding: 0 !important;
  }
}

.sapUiBlockLayoutSizeS {
  .sapUiBlockCellContent {
    padding: 0 !important;
  }
}

.sapUiBlockLayoutSizeXS {
  .sapUiBlockCellContent {
    padding: 0 !important;
  }
}

.visColumn {
  padding: 0 !important;
}

#visBody {

  button {
    background-color: transparent;
    color: inherit;
    margin: 0;
    font-size: 1.125rem;
    padding: 0;
    border-radius: 0;

    &:hover, &:active, &:focus {
      box-shadow: none;
    }
  }

  *, *:after, *:before {
    //box-sizing: inherit;
  }

  p:before, p:after,
  span:before, span:after,
  table tr th:before,
  table tr th:after,
  table tr td:before,
  table tr td:after {
    // content: inherit;
    //display: inherit;
  }

  &:not(.noEmbeded) {
    section {
      &:not(.sapMPageEnableScrolling) {
        overflow: visible !important;
      }
    }
  }

  .sapUiIcon {
    &:after {
      //content: initial;
      //display: inherit;
    }

    &:before {
      display: initial;
    }
  }

  input[type="search"] {
    border: none;
    box-shadow: none;
    font-size: 0.875rem;
    background: transparent;
    width: 100%;
    height: 2.375rem;
    outline: none;
    padding: 0;
    margin: 0;
    text-indent: 0.125rem;
    vertical-align: top;
    text-overflow: ellipsis;

    &:hover, &:active, &:focus {
      border: none !important;
      text-shadow: none !important;
    }
  }

}


@import "oevbs.styleguide.less";
@import "roadmap";
@import "messages";
@import "overwrites.oevbs.less";
@import "overwrites.blocklayout.less";
@import "fonts";
@import "maintenance.less";
@import "helpdesk.fragment.less";
@import "stepA.service.fragment.less";
@import "stepB.contactData.fragment.less";
@import "stepB.banktData.fragment.less";
@import "stepB.total.fragment.less";
//Berater-Suche
@import "stepB.agency.fragment.less";
@import "stepC.overview.fragment.less";
@import "stepC.consultation.fragment.less";
@import "stepC.completion.fragment.less";
@import "stepC.send.fragment.less";
@import "stepC.thanks.fragment.less";
@import "overwrites.popover.less";
@import "overwrites.datepicker.less";
@import "overwrites.mobile.less";

//Berater-Suche
.sapMInputBaseContentWrapper {
  border: 0.4px solid #bfbfbf !important;
  background: #ffffff;
}

.sapMCrslBulleted > span:before {
  content: none !important;
}

.sapMCrslBulleted > span:after {
  content: none !important;
}

.sapMInputHighlight:after {
  content: none !important;
}

.sapMInputHighlight:before {
  content: none !important;
}


#visBody {
  .body;

  &.sapUiBody {
    .body;
  }

  min-height: 500px;

  &.loading {
    #content {
      display: none;
    }

    #init {
      display: block;
      .headline;

      span {
        animation-name: blink;
        animation-duration: 1.4s;
        animation-iteration-count: infinite;
        animation-fill-mode: both;

        &:after, &:before {
          display: none;
          content: "";
        }
      }

      span:nth-child(2) {
        animation-delay: .2s;
      }

      span:nth-child(3) {
        animation-delay: .4s;
      }
    }
  }

  #init {
    display: none;
  }

  span, table {
    font-size: inherit;
    font-weight: inherit;
    color: inherit;
  }

  .sapUiBlockLayoutCell {
    &.wHL {
      h2 {
        .headline !important;
      }
    }
  }

  .sapMFT {
    .wHL {
      h3 {
        .headline;
      }
    }
  }

  .fo-question {
    cursor: help !important;
  }

  .curr {
    &:after {
      content: ' €';
      display: inline;
    }
  }

  .fleft {
    float: left;
  }

  .fright {
    float: right;
  }

  .dIF {
    display: inline-flex !important;
  }

  .version {
    display: block;
    text-align: center;
    width: 100%;
    color: @color_greyblack;
    font-size: 1rem;
  }

  .w100 {
    width: 100% !important;
  }

  .h100 {
    height: 100% !important;
  }

  @iterations: 40;
  .wrem-loop (@i) when (@i > 0) {
    .w@{i}rem {
      width: ~"@{i}rem !important";
    }
    .wrem-loop(@i - 1);
  }
  .wrem-loop (@iterations);

  .flexGrow {
    flex-grow: 1;
  }

  .mr05rem {
    margin-right: 0.5rem;
  }

  .mr1rem {
    margin-right: 1rem !important;
  }

  .mb1rem {
    margin-bottom: 1rem;
  }

  .noBorder {
    border: none !important;
  }

  .sapUiLocalBusyIndicator,
  .sapUiBusyIndicator,
  .sapUiBLy {
    z-index: 200 !important;
  }

  .sapMDialogPhone {
    z-index: 220 !important;
  }

  .sapUiBLy {
    background-color: #eee !important;
  }

  .sap-ui-blocklayer-popup {
    z-index: 199 !important;
    background-color: transparent;
  }

  #sapUiBusyIndicator,
  .sapUiLocalBusy {
    z-index: 201 !important;

    .sapUiLocalBusyIndicator {
      z-index: 201 !important;

      .sapUiLocalBusyIndicatorAnimation {
        background: url('../img/loading-oevbs-s.gif') no-repeat;
        background-size: 100% 100%;
        width: 50px !important;
        height: 50px !important;
        left: ~"calc(100% / 2 - 25px)" !important;
        top: ~"calc(100% / 2 - 25px)" !important;
        z-index: 201 !important;
        position: fixed;

        > div {
          display: none !important;
        }
      }
    }
  }

  .btnMR {
    .sapMBtn {
      margin-right: 0.5rem;
    }
  }

  .blockForm {
    overflow: visible;
    padding-bottom: 1rem;
    border-bottom: 2px solid @c_5;

    .sapUiBlockLayoutCell {
      margin: 0 0.5rem;
    }

    .sapUiBlockCellContent {
      margin-top: 16px;
    }

    .wHeadline {
      .sapUiBlockLayoutCell {
        margin: 0;
      }
    }

    .sapUiBlockCellTitle {
      margin-bottom: 0;
    }

    .sapMSlt {
      margin-top: 1px;
    }

    .formText {
      font-size: 1rem !important;
      line-height: 33px;
      height: 40px;
      vertical-align: bottom;
    }

    .sapMRbB {
      .sapMRbBInn {
        margin-top: 1px;
      }
    }

    .sapMRb {
      clear: none;
      margin: 0.5rem 0.75rem 0.5rem 0;
      width: 100% !important;

      &:focus:before {
        width: 100% !important;
        left: 0 !important;
        top: 0.125rem !important;
      }

      .sapMRbB {
        top: 0;
        height: 2rem;
        width: 2rem;

        .sapMRbBOut {
          margin: 0;
          padding-top: 0;
        }
      }

      .sapMRbBLabel {
        max-width: ~"calc(100% - 2rem + 1px)";
      }

      .sapMLabel {
        cursor: pointer;
      }
    }


    .sapMRbG {
      .sapMRb {
        &:focus:before {
          width: 100% !important;
          left: 0 !important;
          top: 0.125rem !important;
        }

        .sapMRbB {
          height: 2rem;
          width: 2rem;

          .sapMRbBOut {
            margin: 0 0.5rem 0 0;
            padding-top: 0 !important;
          }
        }

        .sapMRbBLabel {
          padding-left: 2rem;
          max-width: ~"calc(100% + 1px)";
          height: 2rem;
          line-height: 2rem;
        }
      }
    }

    .sapMCb {
      &.firstChild {
        padding-left: 35px;

        .sapMCbMark {
          left: 0;
        }
      }

      @media (max-width: 560px) {
        padding-left: 35px;
        .sapMCbMark {
          left: 0;
        }
      }

      .sapMLabel {
        cursor: pointer;
      }
    }

    .sapMDP {
      width: 9rem !important;
    }

    .sapMLabel {
      text-overflow: clip;
      overflow: visible;
      white-space: normal;
    }

    .sapMCbLabel {
      line-height: normal;
    }

    .sapMLabel:not(.sapMRbBLabel):not(.sapMCbLabel) {
      padding: 0 0 3px 0;
      cursor: pointer;
    }

    .sapUiHLayout {
      position: relative;

      .sapMLabel:not(.sapMRbBLabel):not(.sapMCbLabel) {
        //float: left;
      }

      .oevbsIco {
        position: absolute;
        top: 0;
        margin: 0 0 0 8px;
      }
    }

    .oevbsIco {
      margin: -2px 0 0 8px;

      &.fleft {
        margin-top: 12px;
        margin-left: -8px;
        margin-right: 1rem;

      }

      &.afterLbl {
        //position: absolute;
        //margin-top: 16px;
      }
    }
  }

  .sapMSF {
    display: inline-block;
  }

  .nextBG {
    background: @c_5;
    padding: 1rem 0.5rem;
    .h3 !important;

    h3 {
      .h3 !important;
    }

    .sapMText {
      font-size: inherit !important;
      font-weight: inherit !important;
    }

    .sapMFT {
      display: inline-block;
      //font-size: inherit !important;
      font-weight: inherit !important;
    }

    .sapMBtn {
      margin-top: 0.5rem;
      margin-right: 0 !important;
    }

    .sapUiBlockLayoutCell,
    .sapUiBlockCellContent {
      margin: 0 !important;
    }
  }

  .sapMCb {
    margin: 0.5rem 0;
    height: inherit !important;
    line-height: normal !important;

    > label {
      text-overflow: clip !important;
      overflow: visible !important;
      white-space: normal !important;
      line-height: normal;
    }

    .sapMCbBg {
      top: 0 !important;
    }
  }

  .sapMRbBLabel {
    height: inherit !important;
    line-height: normal !important;

    &:before {
      display: none;
    }

    top: 13px !important;
    padding-left: .6em;
    position: relative !important;
  }

  .noOverflow {
    overflow: visible !important;
  }

  .noWhiteSpace {
    white-space: normal !important;
  }

  .oevbsIco, .sapMSltArrow {
    color: #000;

    &:before {
      display: inline-block;
    }

    &:after {
      display: none;
      content: "";
    }
  }
}

.noCopy {
  -webkit-user-select: none; /* Chrome all / Safari all */
  -moz-user-select: none; /* Firefox all */
  -ms-user-select: none; /* IE 10+ */
  user-select: none;
}

.checkBoxWrapping > label {
  white-space: normal !important;
  line-height: normal !important;
  vertical-align: middle !important;
}

.checkBoxWrapping > label > bdi::after {
  margin-top: 0px !important;
}

.marginTop10px {
  margin-top: 10px !important;
}

.displayNone {
  display: none;
}

.sapUiCalItemDsbl > span {
  color: rgba(0, 0, 0, 0.5) !important;
}

.kooperationspartnerRow {
  font-size: 1rem !important;
  font-style: italic;
}

.kooperationspartnerBtn {
  .sapMBtnInner {
    border-radius: 5px 5px 5px 5px;
    color: #fff;
    height: 50px;
    line-height: 50px;
    .btnOrange
  }
  .sapMBtnContent {
    height: 50px;
    line-height: 50px;
  }

  &:hover {
    .sapMBtnInner {
      border-color: @oevbs_blue !important;
      background: @oevbs_blue !important;
      color: @btn_default_red;
    }
  }
  &:focus {
    .sapMBtnInner {
      border-color: @color_green !important;
    }
  }
}

.sapMCb.sapMCbWrapped.sapMCbHasLabel>.sapMLabel.sapMLabelWrapped {
  margin: 0 !important;
}

