.sapMDialogFooter {
  padding: 0 !important;
  .sapMBtn {
    padding: 0;
    &.sapMBarChild {
      background-color: @oevbs_red;
       bdi {
         color: @color !important;
       }
    }
  }
}

/**
 * Mobile und Tablet
 */
.sap-phone,
.sap-tablet {
  #DigitalSchutzApp {
    margin: 0 0.25rem;
    width: ~"calc(100% - 0.5rem)" !important;
  }

  .sapUiRtt {
    left: 0 !important;
  }
}
/**
  * Mobile
 */
.sap-phone {
  .sapMRb  {
    width: 100%;
    &.wInfo {
      width: ~"calc(100% - 40px)";
    }
  }

  .sapMBtnIcon {
    line-height: 2.25rem;
  }

}

/**
 *Datepicker
 */

.sapUiCal {
  z-index: 112!important;
}

.sapUiCalCancel {
  color: #00679e!important;
}

//Berater-Suche
.sapMCrslBulleted > span:before {
  content: none!important;
}

.sapMCrslBulleted > span:after {
  content: none!important;
}

.sapMInputHighlight:after {
  content: none!important;
}

.sapMInputHighlight:before {
  content: none!important;
}



.sapMDialog {
  z-index: 112 !important;

  .sapMSFI[type="search"] {
    border: none !important;
    box-shadow: none;
    font-size: 0.875rem;
    background: transparent;
    width: 100%;
    height: 2.375rem;
    outline: none;
    padding: 0;
    margin: 0;
    text-indent: 0.125rem;
    vertical-align: top;
    text-overflow: ellipsis;
    &:hover, &:active, &:focus {
      border: none !important;
      text-shadow: none !important;
      background: transparent;
    }
  }

  .sapMSFF {
    border: none;
  }

  .sapMFT {
    padding: 0.5rem;
  }
}