.sap-phone,
.sap-tablet {
  .agencyFrgmt {
    .widthFullForMoble {
      width: 100% !important;
    }
  }
}


.agencyFrgmt {

  .agencyRow {
    .sapUiBlockLayoutCell {
      position: relative;
      background-color: #fff;
      //padding: 1rem;
      padding-bottom: 0.5rem;
      padding-top: 0.5rem;
      margin: 0 0 0;
      text-align: start;

      &.title {
        h2 {
          //color: #0d39ab !important;
          text-align: start;
          font-size: 1.5rem !important;
        }
      }
    }
  }

  .agencyHeadline {
    margin-left: -0.1rem !important;
  }


  .agencyHBox {
    width: 80%;
    height: auto;
    margin: 0 auto;
    justify-content: center;
  }

  .agencyVBox{
    margin-top: 15px;
    margin-bottom: 15px;
    z-index: 0;
    position: relative;
    background: #FFF;
    box-shadow: 0 0 0 1px #E2E9ED;
    padding: 10px;
    box-sizing: border-box;
    transition: box-shadow 0.2s ease,
    transform 0.2s ease,
    z-index 0s 0.2s ease;
    box-shadow: 0 0 0 1px #E2E9ED;
    max-width: 300px;

    &:before {
      content: "";
      display: block;
    }

    &:hover {
      z-index: 1;
      box-shadow: 0 8px 50px rgba(0, 0, 0, 0.2);
      transform: scale(1.05);
      transition: box-shadow 0.2s ease,
      transform 0.2s ease,
      z-index 0s 0s ease;
      cursor: pointer;
    }

    //&:hover {
    //    background-color: #cccccc;
    //    cursor: pointer;
    //}
  }

  .agencyVBox > div:not(:first-child) {
    //margin: 0 auto;
    //width: 65%;
  }

  .agencyImage {
    //width: 100%;
    //height: 100%;
    //object-fit: cover;
    padding-top: 20px;
    max-width: 65%;
    height: auto;
    display: block;
    margin-right: auto;
    margin-left: auto;
    //border: 1px solid #FFF;
  }

  .agency_name {
    color: #313435;
    font-family: 'Roboto',sans-serif;
    font-size: 1.3rem;
    font-weight: 500;
    line-height: 2.625rem;
    padding-top: 10px;
    //height: 5rem;
  }

  .agencyText {
    text-align: center;
  }

  .agencyInfo {
    text-align: center;
    color: #6F808A;
    font-family: 'Roboto', sans-serif !important;
    font-size: 0.875rem !important;
    //line-height: 2.375rem;
  }

  .arrowRight {
    border: solid black;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
  }

  .agencyNextButton {

    margin-top: -10px;

  }

  .agencyInputSearch {
    min-width: 30em;
  }

  .agencyResultImg {
    max-height: 250px;
  }









}

