.overviewFrgmt {
  .overviewRow {

    .mb {
      margin-bottom: 0.375rem;
    }

    margin-top: 1rem;
    .sapUiBlockLayoutCell {
      position: relative;
      background-color: #fff;
      padding: 1rem;
      border: 2px solid @c_5;
      margin-bottom: 1rem;

      .sapUiBlockCellContent {
        margin-top: 0;
      }

      h2 {
        min-height: 60px;
        margin-bottom: 0.5rem;
        color: @oevbs_blue !important;
        font-size: 1.5rem !important;
        line-height: 40px !important;
        vertical-align: bottom;
        padding-left: 55px;
        width: ~"calc(100% - 110px)";
        background-repeat: no-repeat;
        background-size: 75px 75px;
        background-position: 0 0;
      }

      &.policyholder {
        h2 {
          background-image: url('../img/policyholder.png');
          background-position: -20px -18px;
        }

        .sapMFT {
          margin-bottom: 0.375rem;
        }
      }

      &.prempayer {
        h2 {
          background-image: url('../img/beitrag.png');
          background-position: -11px -17px;
        }

        .sapMFT {
          margin-bottom: 0.375rem;
        }
      }

      &.contractDetails {
        h2 {
          padding-left: 50px;
          margin-bottom: 0;
          background-image: url('../img/neueVers.png');
          background-position: -8px -5px;
          background-size: 60px 60px;

        }

        h3 {
          display: block;
          font-weight: 600;
        }
        h4 {
          display: block;
          font-weight: 500;
        }

        .sapUiHLayout {
          position: relative;
          .sapMFT {
            display:inline-block;
          }
          .oevbsIco {
            position: absolute;
            top: 2px;
            left: 65px;
            margin: 0;
          }
        }

      }

      &:first-child {
        margin-left: 0.5rem;
      }
      &:last-child {
        margin-right: 0.5rem;
        margin-bottom: 1rem !important;
      }
    }

  }

  margin-bottom: 0;
  padding-bottom:0 !important;

}

.disabled_rb > div > div {
  border-color: white !important;
  display: none !important;
}

.disabled_rb > div > div :hover {
  border-color: white !important;
}

.disabled_rb > div > div > div{
  background-color: white !important;
  display: none !important;
}