.msgWrapper {
  width: ~"calc(100% - 0rem)";

  .sapMMsgStrip {
    margin: 0 0 0.5rem 0;
  }
}

.errorCtrl {
  input, &.sapMSlt  {
    border: 1px solid @btn_default_red_hover;
    &:focus {
      border-color: @color_red !important;
    }
  }

  &.sapMSF {
    form {
      border: 1px solid @btn_default_red_hover;
      &:focus {
        border-color: @color_red !important;
      }
      .sapMSFB {
        color: @btn_default_red_hover;
      }
    }
    input, &:focus {
      border: none !important;
    }
  }

  bdi {
    padding-right: 1.5rem;
    &:after {
      content: "";
      background-image: url(../svg/inline_validierung_negativ.svg);
      background-repeat:no-repeat;
      position: absolute;
      width: 1.125rem;
      height: 1.125rem;
      margin-left: 0.5rem;
      margin-top:0;
    }
  }

  &.sapMRb {
    .sapMRbBOut {
      border-color: @color_red !important;
    }
    bdi {
      &:after {
        display: none;
      }
    }
  }

  &.sapMCb  {
    .sapMCbBg {
      border-color: @color_red !important;
    }

    bdi {
      &:after {
        margin-top: 11px;
      }
    }
  }
}


.sapUiHLayout {
  > .errorCtrl {
    bdi {
      &:after {
        margin-top: 0 !important;
      }
    }
  }
}

.errMsg {
  color: @color_red !important;
  font-size: 0.75rem;
}