.roadMap {
  width: 100%;
  margin-bottom: 0.5rem;

  span:before, span:after{
    display: none !important;
  }

  .pointer {
    height: 40px;
    background: @oevbs_blue2;
    margin-right: 1px;
    z-index: 5;
    position: relative;
    color: @color;

    &:before {
      content: "";
      position: absolute;
      right: -19px;
      bottom: 0;
      width: 0;
      height: 0;
      border-left: 20px solid @oevbs_blue2;
      border-top: 20px solid transparent;
      border-bottom: 20px solid transparent;
    }

    &:after {
      content: "";
      position: absolute;
      left: 0;
      bottom: 0;
      width: 0;
      height: 0;
      border-left: 20px solid white;
      border-top: 20px solid transparent;
      border-bottom: 20px solid transparent;
    }

    &:first-child {
      margin-left:0;
      background: @oevbs_blue;
      &:after {
        border-left: 0;
      }
      &:before {
        border-left-color: @oevbs_blue;
      }
    }

    &:nth-child(2) {
      z-index: 4;
    }
    &:nth-child(3) {
      z-index: 3;
    }

    &:last-child {
      z-index: 2;
      &:before {
        border-left: 0;
      }
    }

  }

  > div {
    flex-grow: 1 !important;
    display: inline-flex;
    .pointer;

    .sapMFT {
      color: inherit;
      text-align: center;
      vertical-align: middle;
      width: ~"calc(100% - 20px)";
      padding-left: 20px;
      line-height: 40px;



      span {
        cursor: inherit;
      }
      .no {
        border: 2px solid #fff;
        width: 25px;
        padding-left: 2px;
        height:25px;
        line-height: 22px;
        display: inline-block;
        border-radius: 15px;
        text-align: center;
      }

      .title {
        margin-left:5px;
        @media(max-width: 780px) {
          display: none;
        }
      }

      @media(max-width: 420px) {
        font-size: 0.75rem !important;
        .no {
          width: 20px;
          height: 20px;
          line-height: 17px;
          border-radius: 20px;
        }
      }
    }
  }

  &[data-actualStep='A'] {
    > div {
      &:first-child {
        .title {
          @media (min-width: 414px) {
            display: inline-block;
          }
        }
        .sapMFT {
          cursor: pointer;
          span {
            cursor: inherit;
          }
        }
      }
    }
  }
  &[data-actualStep='B'] {
    > div {
      &:nth-child(1),
      &:nth-child(2) {
        background: @oevbs_blue;
        .sapMFT {
          cursor: pointer;
        }
        &:before {
          border-left-color: @oevbs_blue;
        }
      }
      &:nth-child(2){
        .title {
          @media (min-width: 414px) {
            display: inline-block;
          }
        }
      }
    }
  }
  &[data-actualStep='C'] {
    > div {
      &:nth-child(2),
      &:nth-child(3){
        .sapMFT {
          cursor: pointer;
        }
        background: @oevbs_blue;
        &:before {
          border-left-color: @oevbs_blue;
        }
      }
      &:nth-child(3){
        .title {
          @media (min-width: 414px) {
            display: inline-block;
          }
        }
      }
    }
  }
  &[data-actualStep='D'] {
    > div {
      &:nth-child(2),
      &:nth-child(3),
      &:nth-child(4){
        .sapMFT {
          cursor: pointer;
        }
        background: @oevbs_blue;
        &:before {
          border-left-color: @oevbs_blue;
        }
      }
      &:last-child{
        .title {
          @media (min-width: 414px) {
            display: inline-block;
          }
        }
      }
    }
  }
}