*{
  font-family: "Sparkasse Web", "Helvetica", Helvetica, Arial, sans-serif;
}

.sapMLabel {
  .fontProperties !important;
  cursor: pointer;
}

.sapUiGlobalBackgroundColor,
.sapMGlobalBackgroundColor{
  background-color: #fff !important;
  background-image: none !important;
}

.fb{
  font-weight: bold;
}

//Berater-Suche
.sapMInputBaseContentWrapper {
  border: 0.4px solid #bfbfbf !important;
  background: #ffffff;
}

.sapMCrslBulleted > span:before {
  content: none!important;
}

.sapMCrslBulleted > span:after {
  content: none!important;
}

.sapMInputHighlight:after {
  content: none!important;
}

.sapMInputHighlight:before {
  content: none!important;
}


.sapMRbSel .sapMRbBInn {
  background-color: @oevbs_blue !important;
}

.sapMRbHoverable:hover {
  border-color: @oevbs_blue !important;
}

.sapMCbHoverable:hover {
  border-color: @oevbs_blue !important;
}

.sapMRbB .sapMRbBOut {
  .fontProperties;
}

.sapMInputBaseInner:not(.sapMInputBaseReadonlyInner):not(.sapMInputBaseStateInner):not(.sapMInputBaseDisabledInner):hover {
  border-color: @oevbs_blue;
}


.sapMCbBg.sapMCbMarkChecked:before {
  color: @oevbs_blue !important;
}

.sapMSltHoverable:hover {
  border-color: @oevbs_blue !important;
}


.sapMMsgStrip {
  width: calc(100%);
  margin: 0 1rem 1rem 1rem;
}

.sapMMsgStripIcon > .sapUiIcon {
  font-size: 1rem;
}

.sapUiResponsiveMargin {
  margin: 0 0 1rem 0 !important;
}

.sapMInputBaseInner {
  .fontProperties;
}

.valueStateError{
  border: 2px solid @oevbs_red;
}

.cbValueStateError .sapMCbBg {
  border: 2px solid @oevbs_red;
}

.sapMTitle {
  .fontProperties;
}

.sapMText,
.sapMFT,
.sapMText > div,
.sapMText > span {
  .fontProperties;
  font-size: 1rem !important;
  line-height: 1.375;
  font-weight: normal;
}

.bitMTextRB {
  position: relative;
  top: 10px;
}

.bitUiSmallMarginTop {
  margin-top: 0.8rem !important;
}

.sapUiSizeCompact .sapUiSmallMarginTop {
  margin-top: 0.5rem !important;
}

.bitHBoxH {
  height: 50px;
}

.msgContainer{
  width: 100%;
  /*margin-bottom: 1rem;*/
}

.msgContainer .sapMMsgStrip {
  margin: 0 0 0.3rem 0;
  min-height: 2rem;
}

.sapUiComponentContainer,
.sapUiComponentContainer > div {
  width: 100%;
}

.sapUiComponentContainer {
  overflow: hidden;
}

.mb1rem {
  margin-bottom: 1rem;
}

.mt1rem {
  margin-top: 1rem;
}

.mbt1rem {
  margin: 1rem 0;
}

.sapMLabelMaxWidth.lblMaxWidth100,
.lblMaxWidth100 {
  max-width: 100%;
}

.sapMInputBaseWarningInner {
  border-color: #bfbfbf;
  border-width: 1px;
}
.sapMInputBaseInner:not(.sapMInputBaseReadonlyInner):not(.sapMInputBaseStateErrorInner):not(.sapMInputBaseDisabledInner):hover {
  border-color: @oevbs_blue;
}
.sapMInputBaseInner:not(.sapMInputBaseReadonlyInner):not(.sapMInputBaseStateInner):focus {
  border-color: @oevbs_blue;
}

.sapUiLnk {
  font-size: 13px!important;
}

.confirmationLbl{
  display:inline-block;
  width:150px;
  font-weight:bold;
  padding-right:5px;
}
.confirmationLbl.longTxt {
  width:260px;
}
.confirmationVal {
  display:inline-block;
}

.sapMTile.sapMTileActive {
  background: @oevbs_blue;
}
.sapMStdTileIconDiv {
  color: @oevbs_blue;
}

.sapMIBar-CTX .sapMTitle {
  color: white;
}

.sapMBar .sapMBarContainer  .sapMInputSuggInDialog .sapMInputBaseInner {
  color: inherit;
}

.sapMBar .sapMBarContainer .sapMInputBaseInner {
  color: white;
}

.sapMMessagePage>.sapMPage section {
  max-width: 80%;
}

.contractList .sapMLIB {
  border-bottom: none;
}

.sapUiFormEdit .contractList .sapMText {
  min-height: 1rem;
  padding-top: 0.3125rem;
  padding-bottom: 0;
  line-height: 1.000;
}

.sapUiForm {
  .sapUiFormTitle {
    font-family: @fontFamily !important;
  }
}

.sapMList .sapMIBar .sapMBarChild .sapMBtnIcon {
  color: black !important;
}

.sapMList .sapMIBar .sapMBarChild .sapMBtnHoverable .sapMBtnIcon {
  color: white;
}

.sapMList .sapMLIB.sapMGHLI {
  color: @oevbs_blue;
  background: #f7f7f7;
  border-bottom: 1px solid @oevbs_blue;
}

.sapMLIB.sapMLIBActive {
  background: @oevbs_blue;
  color: white;
}

.sapMLIB.sapMLIBActive .sapMSLIImgIcon {
  color: @color;
}

.sapMSLIImgIcon {
  margin-left: 0;
}

.sapMSegB .sapMSegBBtn {
  color: @oevbs_blue;
}
.sapMSegBBtn.sapMSegBBtnSel {
  background: @oevbs_red;
  color: @color;
  border-color: @oevbs_red;
}
.sapMSegBBtn.sapMSegBBtnFocusable.sapMSegBBtnSel:hover, .sapMSegBBtnFocusable.sapMBtnActive:hover {
  box-shadow: inset 0 0 0 4rem rgba(0,0,0,0.25);
}
.sapMSegBBtnFocusable:hover {
  background: @oevbs_blue !important;
  color: white;
  border-color: @oevbs_blue;
}

.sapMSegBBtn.sapMSegBBtnFocusable.sapMSegBBtnSel:hover, .sapMSegBBtnFocusable.sapMBtnActive:hover {
  box-shadow: inset 0 0 0 4rem rgba(0,0,0,0.25);
}

.sapMSegBBtn.sapMSegBBtnFocusable.sapMSegBBtnSel {
  border-right: 0 solid @oevbs_red;
}

.bitF14 {
  font-size: 1rem;
}
.bitF16 {
  font-size: 1.1rem;
}

.sapUiDlg div {
  float: none;
}

/*CustomHeader Panel*/
.sapMPanelHdr .sapMBtnIcon {
  color: white;
}

.sapMPanelHdr .sapMTitle {
  color: @oevbs_blue !important;
  text-shadow: 0 0.0625rem 0 #000000 !important;
  /*font-size: 13px !important;*/
}

.sapMPopover.helpPopover .sapMPanelHdr .sapMTitle >span {
  color: #000000 !important;
  text-shadow: 0 0.0625rem 0 #ffffff !important;
}
/*CustomHeader Panel*/

/* ^^^^^^^^^^^^^^^^^^^^^^^^^^^^ SAP BUTTON Override^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^ */



html {
  &.sap-desktop {
    .sapMToggleBtnPressed,
    .sapMIBar-CTX .sapMToggleBtnPressed,
    .sapMTB-Transparent-CTX .sapMBtnInner.sapMToggleBtnPressed,
    .sapMBtn[data-btnPressed] .sapMBtnInner{
      .toggleBtnPressedBlue !important;
    }

    .sapMBtn:hover>.sapMBtnHoverable .sapMBtnIcon {
      box-shadow: inset 0 0 0 4rem rgba(0,0,0,0.25);
    }


    .sapMBtn:hover>.sapMBtnTransparent.sapMBtnHoverable {
      box-shadow: inset 0 0 0 4rem rgba(0,0,0,0.25);
    }

    .sapMBtn:hover .sapMBtnHoverable.sapMToggleBtnPressed,
    .sapMBtn .sapMBtnHoverable.sapMToggleBtnPressed:hover,
    .sapMTB-Transparent-CTX .sapMBtnHoverable.sapMToggleBtnPressed:hover {
      background-image: none;
      box-shadow: inset 0 0 0 4rem rgba(0,0,0,0.25);
    }

    .sapMBtnTransparent>.sapMBtnIcon {
      color: inherit !important;
    }

    .sapMBtn:focus>.sapMFocusable {
      outline:none !important;
    }

  }
  &.sap-phone, &.sap-tablet {
    .sapMToggleBtnPressed,
    .sapMIBar-CTX .sapMToggleBtnPressed,
    .sapMTB-Transparent-CTX .sapMBtnInner.sapMToggleBtnPressed,
    .sapMBtn[data-btnPressed] .sapMBtnInner{
      .toggleBtnPressedBlue !important;
    }
    //:not(.sapMBtnDisabled)>.sapMBtnInner.sapMBtnActive
    //{
    //  .btnRed;
    //}

    .sapMBtnTransparent>.sapMBtnIcon {
      color: inherit !important;
    }
  }
}


button, button:not(.dropdown) {
  font-size: 1.125rem;
  padding: 0; // 0.5rem 0.875rem;
  background: transparent;


  &.sapMBtn {
    outline: 0;
    &:hover > .sapMBtnHoverable {
      .btnRedHover;

      &.sapMBtnTransparent {
        .btnRedHover;
      }

      &.sapMToggleBtnPressed:not(.sapMBtnActive) {
        .btnRedHover;
      }
      .sapMBtnHoverable.sapMToggleBtnPressed:hover {
        .btnRedHover;
      }
    }
  }

  .sapMBtnInner {
    border-radius: 0.1875rem;
    font-size:1.125rem;
    .btnRed
  }

  .sapMToggleBtnPressed:not(.sapMBtnDisabled),
  .sapMIBar-CTX .sapMToggleBtnPressed:not(.sapMBtnDisabled),
  .sapMTB-Transparent-CTX .sapMBtnInner.sapMToggleBtnPressed:not(.sapMBtnDisabled) {

  }

  &[aria-pressed='false'] {

    &.sapMBtn:hover>.sapMBtnHoverable {
      .btnGreyHover;

      .sapMBtnContent {
      }
    }

    .sapMBtnInner {
      .btnGrey;

      &.sapMBtnActive {
        border-color: @color_darkgrey;
      }
    }
  }
}

.sapUiSizeCompact .sapMBtn {
  margin: 0 0.2rem;
}
.sapMBtnIcon {
  margin: 0 !important;
  padding-left: 0.4375rem;
  padding-right: 0.4375rem;
  background-color: inherit !important;
  color: white;
}




/* ^^^^^^^^^^^^^^^^^^^^^^^^^^^^ SAP BUTTON Override ^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^ */

/* ^^^^^^^^^^^^^^^^^^^^^^^^^^^^ ToolbarButton ^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^ */
.tbInfoBtn{
  border: 1px solid whitesmoke;
}

.tbInfoBtn.sapMBtn {
  height: 1.5rem;
}

.tbInfoBtn .sapMBtnInner {
  height: 1.125rem !important;
  min-width: 1.5rem;
}

.tbInfoBtn .sapMBtnIcon {
  line-height: 1.00;
  width: 0.5rem;
  font-size: 0.75rem;
  margin-left: 0.4375rem;
  margin-right: 0.4375rem;
}

.tbInfoBtn.sapMBtn:hover>.sapMBtnInner {
  box-shadow: inset 0 0 0 4rem rgba(0,0,0,0.25);
}
/* ^^^^^^^^^^^^^^^^^^^^^^^^^^^^ ToolbarButton ^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^ */


.sapMIBar.sapMFooter,
.sapMIBar.sapMFooter-CTX {
  background-color: #DFDFDF !important;
}


.sapMSwt,
.sapMSwt:hover {
  background: #fff;
  color: @oevbs_blue !important;
  font-size: 1rem;

  &.sapMSwtAcceptReject {
    &.sapMSwtOff,  &.sapMSwtOn {
      border-color: @oevbs_blue;
      .sapMSwtHandle {
        border-color: @oevbs_blue;
        background: @oevbs_blue;
      }
    }

    .sapMSwtLabel {
      &::after {
        display: none;
        content: '';
      }
    }

    .sapMSwtLabelOn{
      &::before {
        color: @oevbs_blue;
        display: inline-block;
      }
    }
    .sapMSwtLabelOff {
      padding: 0 0.5rem;
      &::before {
        color: @oevbs_blue;
        padding-right: 0.5rem;
        display: inline-block;
      }
    }
  }
}

.sapMLnk {
  color: @oevbs_red !important;
  .icoL  {
    margin-right: 5px;
  }
}


.sapUiBlockCellTitle {
  &:after, &:before {
    box-sizing: unset !important;
  }
  box-sizing: unset !important;
}

.sapMCb:not(.sapMCbBgDis).sapMCbHasLabel:focus:before {
  height: 100%;
  top: -0.185rem !important;
}

:not(.sapMSFDisabled)>.sapMSFF:hover {
  border-color: @oevbs_blue;
}

.kvisible{
   position: relative;
   z-index: -3;
}

.leistungenpdf{
  position: relative;
  top: 20px;
}