.sap-desktop {
  .sapMPopover {
    z-index: 102 !important;
    &.sapMSltPicker {
      max-height: 450px !important;
      .sapMPopoverCont {
        max-height: 400px !important;
        .sapMPopoverScroll {
          max-height: 400px !important;
        }
      }
    }
  }
}

.sapMPopoverCont {
  padding: 0.5rem;
}