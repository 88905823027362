.serviceFrgmt{
  .leistung {
    //border: 2px solid #002566;
    border-bottom: none;
  }
  .leistung_items {
    //border-bottom: 2px solid #002566;
    padding: 1rem;
    width: -webkit-fill-available;
  }
  .leistung_help {
    position: unset !important;
    cursor: help;
    padding-right: 2rem;
  }
  .leistung_icon {
    font-size: 1.5rem !important;
    padding-top: 0.2rem;

  }
  .leistung_text {
    padding-left: 1rem;
  }

  .leistung_beitrag {
    float: right;
    padding: 1rem;
  }

  .gesamt_beitrag {
    padding: 1rem;
  }

  .leistungTotalRow {
    width: 100%;
    font-size: 1.375rem !important;
    background-color: #e0e0e0;

    &.totalBlock {
      font-size: 1.5rem !important;
    }

    .sapMText {
      font-size: inherit !important;
      font-weight: inherit !important;
    }

    .sapMFT {
      display: inline-block;
      font-size: inherit !important;
      font-weight: inherit !important;
    }
  }

}