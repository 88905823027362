.totalBlockWrapper {

  .totalRow {
    width: 100%;
    font-size: 1.375rem !important;

    .suffixTxt {
      font-size: 1rem !important;
    }

    &.extraOption {
      .fo-plus,
      .fo-minus{
        line-height: inherit;
        font-size: 1rem !important;
        margin:0 0.5rem 0 0.75rem;
      }
    }

    &.totalBlock {
      margin-top: 0.5rem !important;
      font-size: 2rem !important;

      @media (min-width: 320px) and (max-width: 480px) {
        font-size: 1rem !important;
        line-height: 1.25rem !important;
      }

    }

    .sapMText {
      font-size: inherit !important;
      font-weight: inherit !important;

      @media (min-width: 320px) and (max-width: 480px) {
        font-size: 1.125rem !important;
        line-height: 1.25rem !important;
      }
    }

    .sapMFT {
      display: inline-block;
      font-size: inherit !important;
      font-weight: inherit !important;

      @media (min-width: 320px) and (max-width: 480px) {
        font-size: 1rem !important;
        line-height: 1.25rem !important;
      }
    }
  }

  button {
    margin-left: 1rem;
  }
}