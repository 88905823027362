
.helpdeskWrapper {
  width: 100%;
  text-align: center;
  margin: 0;
  font-size: 0.75rem;

  .sapUiTv {
    color: @oevbs_blue;
  }
  .sapUiIcon {
    color: @oevbs_blue;
    margin: 0 0 0 5px;
    cursor: context-menu;
  }
}

.hdDialog {
  width:100%;
  height: 100%;
  z-index: 999 !important;

  .sapMDialogScroll {
    height:100%;
    .sapMDialogScrollCont {
      height: ~"calc( 100% - 30px)";
    }
    .tabfilter {
      height: ~"calc( 100% - 30px)";

      .sapMITBContainerContent {
        height: ~"calc( 100% - 30px)";

        .sapMITBContent {
          height: 100%;

          & > .sapUiVlt.sapuiVlt {
            height: 100%;

            .sapUiVltCell.sapuiVltCell:last-child {
              height: ~"calc( 100% - 30px)";
              display: flex;
              flex-direction: column;
              .sapMTextArea {
                height: 100%;
                textarea {
                  flex-grow: 1;
                }
              }
            }
          }
        }
      }
    }

    .hdMenuBtns {
      margin-right: 5px;
      &.active {
        background-color: @oevbs_blue !important;
        color: #fff !important;
      }
    }
  }
}