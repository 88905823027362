.sapUiBlockLayoutSizeXL {
  .sapUiBlockCellContent > .sapMText {

  }
}

  .sapUiBlockCellTitle {
    .h3 !important;
  }

  .sapUiBlockLayout  {
    margin-bottom: 1rem;
  }
