.maintenanceV {
  .sapUiBlockLayoutCell {
    margin-right:1rem;
    &:first-child {
      text-align: center;
    }
    &:last-child{
      margin-right: 0;
    }
  }
  .img {
  }
  .sapMFT {
    margin-top:2rem;
    font-size: 1.5rem;
  }
}