
@font-face {
  font-family: "oevbs-iconfont";
  src: url("../fonts/oevbs-iconfont.eot");
  src: url("../fonts/oevbs-iconfont.eot?#iefix") format("embedded-opentype"),url("../fonts/oevbs-iconfont.woff2") format("woff2"),url("../fonts/oevbs-iconfont.woff") format("woff"),url("../fonts/oevbs-iconfont.ttf") format("truetype"),url("../fonts/oevbs-iconfont.svg#oevbs-iconfont") format("svg");
  font-weight: normal;
  font-style: normal
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  @font-face {
    font-family:"oevbs-iconfont";
    src: url("../fonts/oevbs-iconfont.svg#oevbs-iconfont") format("svg")
  }
}

[data-icon]:before {
  content: attr(data-icon)
}

[data-icon]:before,.fo-alert:before,.fo-arrow-down:before,.fo-arrow-left:before,.fo-arrow-right:before,.fo-arrow-up:before,.fo-backward:before,.fo-bar:before,.fo-bullet:before,.fo-bullet-arrow:before,.fo-calculator:before,.fo-calendar:before,.fo-check:before,.fo-clock:before,.fo-customer:before,.fo-delete:before,.fo-dislike:before,.fo-dot:before,.fo-doublearrow-down:before,.fo-doublearrow-up:before,.fo-download:before,.fo-exclamation:before,.fo-forward:before,.fo-id-card:before,.fo-like:before,.fo-mail:before,.fo-memorize:before,.fo-menu:before,.fo-minus:before,.fo-mobile-berater:before,.fo-mobile-menue:before,.fo-mobile-menue-aktiv:before,.fo-mobile-menue-hoch:before,.fo-mobile-menue-hoch-aktiv:before,.fo-padlock:before,.fo-padlock-open:before,.fo-paper:before,.fo-paper-blank:before,.fo-pencil:before,.fo-phone:before,.fo-place:before,.fo-play-pause:before,.fo-plus:before,.fo-print:before,.fo-question:before,.fo-reload:before,.fo-search:before,.fo-service-numbers:before,.fo-speaker:before,.fo-table-pagination:before,.fo-trash-bin:before,.fo-world:before {
  display: inline-block;
  font-family: "oevbs-iconfont";
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  line-height: 1;
  text-decoration: inherit;
  text-rendering: optimizeLegibility;
  text-transform: none;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased
}

.fo-alert:before {
  content: "\62"
}

.fo-arrow-down:before {
  content: "\4b"
}

.fo-arrow-left:before {
  content: "\4a"
}

.fo-arrow-right:before {
  content: "\71"
}

.fo-arrow-up:before {
  content: "\4c"
}

.fo-backward:before {
  content: "\7a"
}

.fo-bar:before {
  content: "\48"
}

.fo-bullet:before {
  content: "\45"
}

.fo-bullet-arrow:before {
  content: "\52"
}

.fo-calculator:before {
  content: "\6a"
}

.fo-calendar:before {
  content: "\64"
}

.fo-check:before {
  content: "\70"
}

.fo-clock:before {
  content: "\4f"
}

.fo-customer:before {
  content: "\6c"
}

.fo-delete:before {
  content: "\74"
}

.fo-dislike:before {
  content: "\61"
}

.fo-dot:before {
  content: "\4d"
}

.fo-doublearrow-down:before {
  content: "\53"
}

.fo-doublearrow-up:before {
  content: "\54"
}

.fo-download:before {
  content: "\42"
}

.fo-exclamation:before {
  content: "\41"
}

.fo-forward:before {
  content: "\43"
}

.fo-id-card:before {
  content: "\50"
}

.fo-like:before {
  content: "\73"
}

.fo-mail:before {
  content: "\46"
}

.fo-memorize:before {
  content: "\69"
}

.fo-menu:before {
  content: "\66"
}

.fo-minus:before {
  content: "\63"
}

.fo-mobile-berater:before {
  content: "\55"
}

.fo-mobile-menue:before {
  content: "\56"
}

.fo-mobile-menue-aktiv:before {
  content: "\57"
}

.fo-mobile-menue-hoch:before {
  content: "\58"
}

.fo-mobile-menue-hoch-aktiv:before {
  content: "\59"
}

.fo-padlock:before {
  content: "\6e"
}

.fo-padlock-open:before {
  content: "\6f"
}

.fo-paper:before {
  content: "\68"
}

.fo-paper-blank:before {
  content: "\67"
}

.fo-pencil:before {
  content: "\75"
}

.fo-phone:before {
  content: "\6d"
}

.fo-place:before {
  content: "\49"
}

.fo-play-pause:before {
  content: "\79"
}

.fo-plus:before {
  content: "\77"
}

.fo-print:before {
  content: "\44"
}

.fo-question:before {
  content: "\78";
  cursor: help;
}

.fo-reload:before {
  content: "\47"
}

.fo-search:before {
  content: "\65"
}

.fo-service-numbers:before {
  content: "\6b"
}

.fo-speaker:before {
  content: "\76"
}

.fo-table-pagination:before {
  content: "\72"
}

.fo-trash-bin:before {
  content: "\51"
}

.fo-world:before {
  content: "\4e"
}

.fo {
  display: inline-block;
  font-family: "oevbs-iconfont";
  font-style: normal;
  font-variant: normal;
  line-height: 1;
  text-decoration: inherit;
  text-rendering: optimizeLegibility;
  text-transform: none;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  font-family: "Sparkasse Web","Helvetica",Helvetica,Arial,sans-serif
}

.fo:before {
  font-family: "oevbs-iconfont"
}

.fo-close:before {
  content: "t"
}

@font-face {
  font-family: 'Sparkasse Web';
  src: url("../fonts/sparrg-webfont.eot");
  src: url("../fonts/sparrg-webfont.eot?#iefix") format("embedded-opentype"),url("../fonts/sparrg-webfont.woff") format("woff"),url("../fonts/sparrg-webfont.ttf") format("truetype"),url("../fonts/sparrg-webfont.svg#sparkasse_rgregular") format("svg");
  font-weight: normal;
  font-style: normal
}

@font-face {
  font-family: 'Sparkasse Web';
  src: url("../fonts/sparrgit-webfont.eot");
  src: url("../fonts/sparrgit-webfont.eot?#iefix") format("embedded-opentype"),url("../fonts/sparrgit-webfont.woff") format("woff"),url("../fonts/sparrgit-webfont.ttf") format("truetype"),url("../fonts/sparrgit-webfont.svg#sparkasse_rgitalic") format("svg");
  font-weight: normal;
  font-style: italic
}

@font-face {
  font-family: 'Sparkasse Web';
  src: url("../fonts/sparlt-webfont.eot");
  src: url("../fonts/sparlt-webfont.eot?#iefix") format("embedded-opentype"),url("../fonts/sparlt-webfont.woff") format("woff"),url("../fonts/sparlt-webfont.ttf") format("truetype"),url("../fonts/sparlt-webfont.svg#sparkasse_ltregular") format("svg");
  font-weight: 200;
  font-style: normal
}

@font-face {
  font-family: 'Sparkasse Web';
  src: url("../fonts/sparltit-webfont.eot");
  src: url("../fonts/sparltit-webfont.eot?#iefix") format("embedded-opentype"),url("../fonts/sparltit-webfont.woff") format("woff"),url("../fonts/sparltit-webfont.ttf") format("truetype"),url("../fonts/sparltit-webfont.svg#sparkasse_ltitalic") format("svg");
  font-weight: 200;
  font-style: italic
}

@font-face {
  font-family: 'Sparkasse Web';
  src: url("../fonts/sparbd-webfont.eot");
  src: url("../fonts/sparbd-webfont.eot?#iefix") format("embedded-opentype"),url("../fonts/sparbd-webfont.woff") format("woff"),url("../fonts/sparbd-webfont.ttf") format("truetype"),url("../fonts/sparbd-webfont.svg#sparkasse_rgbold") format("svg");
  font-weight: bold;
  font-style: normal
}

@font-face {
  font-family: 'Sparkasse Web';
  src: url("../fonts/sparbdit-webfont.eot");
  src: url("../fonts/sparbdit-webfont.eot?#iefix") format("embedded-opentype"),url("../fonts/sparbdit-webfont.woff") format("woff"),url("../fonts/sparbdit-webfont.ttf") format("truetype"),url("../fonts/sparbdit-webfont.svg#sparkasse_rgbold_italic") format("svg");
  font-weight: bold;
  font-style: italic
}