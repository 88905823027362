.completionFrgmt {
  position: relative;
  background-color: #fff;
  padding: 1rem;
  margin: 0 0.5rem;
  border: 2px solid @c_5;
  .completionRow {

    .sapUiBlockLayoutCell {

      h2 {
        margin-bottom: 0.5rem;
        color: @oevbs_blue !important;
        font-size: 2.25rem !important;
        line-height: 2.625rem !important;
        min-height: 100px;
        vertical-align: middle;
        padding-top: 0.5rem;
        padding-left: 110px;
        width: ~"calc(100% - 110px)";
        background-repeat: no-repeat;
        background-size: 100px 100px;
        background-position: 0 -5px;
      }

      &.contract {
        h2 {
          background-image: url('../img/contractDetails.png');
        }
      }
    }

  }
  .completionRowEnd {
    background-color: @oevbs_blue !important;

    .sapUiBlockLayoutCell {
      div {
        color : white !important;
      }

      label {
        color: white !important;
      }
    }

  }

  .sapMCb {
    padding: 0 0 0 2rem;
    .sapMCbBg {
      left: 0;
    }
  }
}