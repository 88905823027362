@fontFamily: "Sparkasse Web", "Helvetica", Helvetica, Arial, sans-serif;

@oevbs_blue:#0d39ab;
@oevbs_blue2:#5977c6 ;
@oevbs_red: #d62018;

@bg_blue: @oevbs_blue;
@bg_blue_gradient: #1b3d93;
@bg_header_gradient: linear-gradient(to right, @bg_blue, @bg_blue_gradient);

@bg_header_gradient_hover: linear-gradient(to right, #001c4d, #17347d);
@bg_teaser_btn_color: #164194;


@c_75: @oevbs_blue2;
@c_30: #738ccf;
@c_20: #a6b6e0;
@c_14: #bfcbe9;
@c_5: #d9e0f2;

@btn_default_red: #da291c;
@btn_default_orange: #ff5900;
@btn_default_red_hover: #da291c;

@btn_default_blue: #0d39ab;
@btn_default_blue_hover: #0d39ab;

@btn_hover_shadow: inset 0 0 0 4rem rgba(0,0,0,0.25);

@color: #fff;
@color_greyblack: #3c3c3b;
@color_darkgrey: #4d4d4c;
@color_lightgrey: #878787;
@color_blue: @oevbs_blue;
@color_blue2: @oevbs_blue2;
@color_red: #da291c;
@color_green: #97d700;

.btnBlue {
  background: @color_blue;
  border-color: @color_blue;
  color: @color;
  text-shadow: none;
  span {
    color: inherit;
  }
  .sapUiIcon {
    color: @color;
  }
}

.btnRed {
  background: @btn_default_red;
  color: @color !important;
  text-shadow: none;
  span {
    color: inherit;
  }
  .sapUiIcon {
    color: @color;
  }
}

.btnOrange {
  background: #5977c6 !important;
  border-color: #5977c6;
  color: white !important;
  text-shadow: none;
  span {
    color: inherit;
  }
  .sapUiIcon {
    color: @color;
  }
}

.btnRedHover {
  background: @btn_default_red_hover;
  color: @color !important;
  text-shadow: none;
  box-shadow: inset 0 0 0 4rem rgba(0,0,0,0.25);
  span {
    color: inherit;
  }
}

.btnGrey {
  background: @c_5;
  border-color: @c_14;
  color: @color_darkgrey !important;
  text-shadow: none;
  span {
    color: inherit;
  }
}

.btnGreyHover {
  background: @c_5;
  text-shadow: none;
  color: @color_darkgrey !important;
  box-shadow: inset 0 0 0 4rem rgba(0,0,0,0.25);
  span {
    color: inherit;
  }
}

.toggleBtnPressed {
  background: @btn_default_red;
  border-color: @btn_default_red;
  color: @color;
  text-shadow: none;
}

.toggleBtnPressedBlue {
  background: @bg_blue_gradient;
  border-color: @color_blue;
  color: @color;
  text-shadow: none;
}

.body {
  background: #fff;
  color: @color_greyblack;
  padding: 0;
  margin: 0;
  font-family: @fontFamily !important;
  font-weight: 200;
  font-style: normal;
  line-height: 1.25rem;
  position: relative;
  cursor: default;
  margin-top: 2rem;
}

.fontProperties {
  font-family: @fontFamily !important;
  font-size: 1rem;
  color: @color_greyblack;
}

.blockHeaderBg {
  background: @bg_header_gradient;
  background-size: 100% 55px;
  background-repeat: no-repeat;

  .sapUiBlockCellTitle {
    &.sapUiBlockCellBegin {
      color: @color;
      margin-bottom: 1.5rem;
    }
  }
}

.red {
  color: @color_red !important;
}
.green {
  color: @color_green !important;
}

.headline {
  font-size: 2.25rem;
  line-height: 2.625rem;
  color: @oevbs_blue;
  margin-bottom: 0.938rem;

  @media (min-width: 320px) and (max-width: 480px) {
    font-size: 1.75rem !important;
    line-height: 1.75rem !important;
  }
}

.h3 {
  font-weight: normal;
  font-size: 1.375rem;
  line-height: 1.625rem;

  @media (min-width: 320px) and (max-width: 480px) {
    font-size: 1.125rem !important;
    line-height: 1.25rem !important;
  }

  color: @color_greyblack;
}