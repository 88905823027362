.consultationFrgmt {
  .consultationRow {

    .sapUiBlockLayoutCell {
      position: relative;
      background-color: #fff;
      padding: 1rem;
      margin: 0 0.5rem;
      border: 2px solid @c_5;

      h2 {
        margin-bottom: 0.5rem;
        color: @oevbs_blue !important;
        font-size: 2.25rem !important;
        line-height: 2.625rem !important;
        min-height: 100px;
        vertical-align: middle;
        padding-top: 0.5rem;
        padding-left: 110px;
        width: ~"calc(100% - 110px)";
        background-repeat: no-repeat;
        background-size: 100px 100px;
        background-position: 0 0;
        background-position-y: -10px;
      }

      &.berater {
        h2 {
          background-image: url('../img/berater.png');
        }
      }
    }

    .errMsg {
      margin-top: 1rem;
    }

    .sapMCb {
      padding: 0 0 0 2rem;
      .sapMCbBg {
        left: 0;
      }
    }
  }
}

